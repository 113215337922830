import React from 'react'
import guiltyDavidImg from '../img/GD text.svg';

export default function GuiltyDavid() {
  return (
    <div className='guiltyDavidGrid'>
        <img className='guiltyDavidImg' src={guiltyDavidImg} />
    </div>
  )
}
